'use client' // Error components must be Client Components

import { useRouter } from 'next/navigation'
import { useEffect } from 'react'

export default function Error({
  error,
  reset
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])

  const router = useRouter()

  return (
    <section className="section-border border-primary">
      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center gx-0 min-vh-100">
          <div className="col-12 col-md-5 col-lg-4 py-8 py-md-11">
            <h1 className="display-3 fw-bold text-center">Uh Oh!</h1>

            <p className="mb-5 text-center text-body-secondary">
              We ran into an issue, but don’t worry, we’ll take care of it for
              sure.
            </p>

            <div className="text-center">
              <button className="btn btn-primary" onClick={() => router.back()}>
                Back to safety
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
